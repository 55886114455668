import React from "react";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { LogoImage } from "../assets/images";
import { Helmet } from "react-helmet";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Privé - About`} </title>
        <meta name="title" content={`Privé - About`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Privé - About`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://privé.xyz/about`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Privé - About`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://privé.xyz/about`} />

        <link rel="canonical" href={`https://privé.xyz/about`} />
      </Helmet>
      <CommonTopBar selectedCountry="About" />
      <main>
        <div className="main-content">
          <div className="main-content-title-wrapper without-filters">
            <div className="main-content-title-container">
              <h1 className="selected-country-name">About</h1>
              <Navigation />
            </div>
          </div>
          {/* <h1 className="page-titles">Top 50 Escorts</h1> */}
          <div className="escort-card-wrapper">
            <div className="escort-card-container about">
              <div className="content">
                <h3>
                  Welcome to Privé, where we specialize in creating
                  extraordinary moments and forging connections that transcend
                  the ordinary.
                </h3>

                <p>
                  At Privé, we believe that true luxury lies in the details.
                  From the moment you engage our services, you'll experience the
                  pinnacle of sophistication, discretion, and personalized
                  attention. Our meticulously curated selection of companions
                  represents the epitome of elegance, each possessing a unique
                  blend of charm, intelligence, and allure.
                </p>

                <p>
                  Whether you're seeking a glamorous partner for a high-profile
                  event, a companion for a weekend getaway, or an intimate
                  encounter in the privacy of your own space, Privé is dedicated
                  to fulfilling your every desire with grace and finesse.
                </p>

                <p>
                  We understand that discretion is paramount, which is why we
                  take every measure to ensure the confidentiality and safety of
                  both our clients and companions. Our thorough screening
                  process and commitment to professionalism guarantee that every
                  encounter is conducted with the utmost respect and integrity.
                </p>

                <p>
                  At Privé, we don't just provide companionship; we curate
                  experiences that linger in the memory long after they've
                  ended. From the moment you make contact with us to the
                  conclusion of your encounter, our team is dedicated to
                  ensuring that every aspect of your experience is nothing short
                  of exceptional.
                </p>

                <p>
                  Indulge in the luxury you deserve and elevate your experiences
                  with Privé. Contact us today to embark on a journey of
                  unparalleled elegance, where every moment is crafted to
                  perfection and every encounter is a celebration of refined
                  indulgence.
                </p>
              </div>

              <div className="img-wrapper">
                <img src={LogoImage} alt="" />
              </div>
            </div>
          </div>

          <FooterSection />
        </div>
      </main>
    </>
  );
};

export default About;
