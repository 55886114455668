import React, { useState } from "react";
import { ReactComponent as HamburgerSvg } from "../../assets/svg/hamburger.svg";
import { ReactComponent as ProfileSvg } from "../../assets/svg/profile.svg";

import { LogoImage } from "../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";

const CommonTopBar = ({ selectedCountry, links }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openAuth, setOpenAuth] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  return (
    <div className="nav-bar-wrapper">
      <div className="nav-bar-container">
        <img
          src={LogoImage}
          alt="Logo EscortHub"
          onClick={() => navigate(`/`)}
          style={{ cursor: "pointer" }}
          width="100px"
          height="100px"
        />

        <h1 className="desktop-title">{selectedCountry}</h1>
        <button className="profile-toggle menu-toggle" onClick={() => setOpenMenu(!openMenu)}>
          <i className="svg-wrapper">
            <HamburgerSvg />
          </i>
        </button>

        <div
          className="auth-wrapper menu-items user-menu"
          style={{ display: openMenu ? "grid" : "none" }}
        >
          <span className="glasspane" onClick={() => setOpenMenu(!openMenu)}></span>
          <div>
            <nav className="login-wrapper navigation">
              <li onClick={() => navigate(`/`)} className={location.pathname === "/" && "active"}>
                Escorts
              </li>
              <li
                onClick={() => {
                  navigate("/top-50");
                  setOpenMenu(!openMenu);
                }}
                className={location.pathname === "/top-50" && "active"}
              >
                Top 50 Escorts
              </li>
              <li
                onClick={() => {
                  navigate("/porn-stars");
                  setOpenMenu(!openMenu);
                }}
                className={location.pathname === "/porn-stars" && "active"}
              >
                Pornstar Escorts
              </li>
            </nav>
          </div>
        </div>

        <div className="auth-wrapper" style={{ display: openAuth ? "grid" : "none" }}>
          <span className="glasspane" onClick={() => setOpenAuth(!openAuth)}></span>
          <div>
            {/* <button onClick={() => navigate("/auth/signup")}>Create an account ? </button> */}
          </div>
        </div>

        <div
          className="auth-wrapper filters-popup"
          style={{ display: openFilters ? "grid" : "none" }}
        >
          <span className="glasspane" onClick={() => setOpenFilters(!openFilters)}></span>
          <div>
            <h1>Search</h1>
          </div>
        </div>

        <div className="top-bar-menu-buttons">
          {localStorage.getItem("uu_session") ? (
            <button className="top-bar-button" onClick={() => navigate("/admin/dashboard")}>
              Dashboard
            </button>
          ) : (
            <button
              className="profile-toggle middle-button"
              onClick={() => navigate("/auth/login")}
            >
              <i className="svg-wrapper">
                <ProfileSvg />
              </i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommonTopBar;
