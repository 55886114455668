import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { getClientInstance } from "../../helpers/middleware.helper";
import appConfigStore from "../../store/appConfigStore";
import AgencyTopbar from "../../components/layouts/AgencyTopbar";
import AgencyDashboard from "./AgencyDashboard";
import MyAds from "./MyAds";
import AgencyProfile from "./AgencyProfile";
import AgencyAddAdvertisement from "./AgencyAddAdvertisement";
import AgencyUpdateAdvertisement from "./AgencyUpdateAdvertisement";
import { Helmet } from "react-helmet";
import { LogoImage } from "../../assets/images";

const Agency = () => {
  const { isLoading, setIsLoading } = appConfigStore();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const initializeInstance = async () => {
      setIsLoading(true);
      const instance = await getClientInstance();

      if (instance === 1) {
        navigate("/admin/dashboard");
      } else if (instance === 2) {
        if (location.pathname === "/agency" || location.pathname === "/agency/")
          navigate("/agency/dashboard");
      } else if (instance === 3) {
        navigate("/escort/dashboard");
      } else {
        navigate("/");
      }
      setIsLoading(false);
    };

    initializeInstance();
  }, [location.pathname, navigate, setIsLoading]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Privé - Dashboard`} </title>
        <meta name="title" content={`Privé - Dashboard`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Privé - Dashboard`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://privé.xyz`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Privé - Dashboard`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://privé.xyz`} />

        <link rel="canonical" href={`https://privé.xyz`} />
      </Helmet>
      <AgencyTopbar />
      {isLoading && <div className="overlay" />}

      <Routes>
        <Route path="dashboard" element={<AgencyDashboard />} />
        <Route path="my-ads" element={<MyAds />} />
        <Route path="my-profile" element={<AgencyProfile />} />
        <Route path="create" element={<AgencyAddAdvertisement />} />
        <Route path="/update/:id" element={<AgencyUpdateAdvertisement />} />
      </Routes>
    </>
  );
};

export default Agency;
