import React from "react";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { LogoImage } from "../assets/images";
import { Helmet } from "react-helmet";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";

const Terms = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Privé - Terms & Conditions`} </title>
        <meta name="title" content={`Privé - Terms & Conditions`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Privé - Terms & Conditions`} />

        <meta property="og:image" content={LogoImage} />
        <meta
          property="og:url"
          content={`https://privé.xyz/terms-conditions`}
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Privé - Terms & Conditions`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta
          name="twitter:url"
          content={`https://privé.xyz/terms-conditions`}
        />

        <link rel="canonical" href={`https://privé.xyz/terms-conditions`} />
      </Helmet>
      <CommonTopBar selectedCountry="Terms & Conditions" />
      <main>
        <div className="main-content">
          <div className="main-content-title-wrapper without-filters">
            <div className="main-content-title-container">
              <h1 className="selected-country-name">Terms & Conditions</h1>
              <Navigation />
            </div>
          </div>
          {/* <h1 className="page-titles">Top 50 Escorts</h1> */}
          <div className="escort-card-wrapper">
            <div className="escort-card-container about">
              <div className="content">
                <h3>Terms and Conditions</h3>
                <p>
                  Welcome to Privé. By accessing this website, we assume you
                  accept these terms and conditions. Do not continue to use
                  Privé if you do not agree to all of the terms and conditions
                  stated on this page.
                </p>

                <h2>License</h2>
                <p>
                  Unless otherwise stated, Privé and/or its licensors own the
                  intellectual property rights for all material on Privé. All
                  intellectual property rights are reserved. You may access this
                  from Privé for your own personal use subjected to restrictions
                  set in these terms and conditions.
                </p>

                <h2>You must not:</h2>
                <ul>
                  <li>Republish material from Privé</li>
                  <li>Sell, rent or sub-license material from Privé</li>
                  <li>Reproduce, duplicate or copy material from Privé</li>
                  <li>Redistribute content from Privé</li>
                </ul>

                <h2>Reservation of Rights</h2>
                <p>
                  We reserve the right to request that you remove all links or
                  any particular link to our website. You approve to immediately
                  remove all links to our website upon request. We also reserve
                  the right to amend these terms and conditions and it's linking
                  policy at any time. By continuously linking to our website,
                  you agree to be bound to and follow these linking terms and
                  conditions.
                </p>

                <h2>Content Liability</h2>
                <p>
                  We shall not be hold responsible for any content that appears
                  on your website. You agree to protect and defend us against
                  all claims that are rising on your website. No link(s) should
                  appear on any website that may be interpreted as libelous,
                  obscene or criminal, or which infringes, otherwise violates,
                  or advocates the infringement or other violation of, any third
                  party rights.
                </p>

                <h2>Changes to Terms</h2>
                <p>
                  We reserve the right, at our sole discretion, to modify or
                  replace these Terms at any time. If a revision is material, we
                  will try to provide at least 30 days' notice prior to any new
                  terms taking effect. What constitutes a material change will
                  be determined at our sole discretion.
                </p>

                <p>
                  Please review these terms periodically for any updates or
                  changes.
                </p>
              </div>

              <div className="img-wrapper">
                <img src={LogoImage} alt="" />
              </div>
            </div>
          </div>

          <FooterSection />
        </div>
      </main>
    </>
  );
};

export default Terms;
