import axios from "axios";

const BASE_URL_v1 = "https://prive.luxurylinkup.com/api";
export const BASE_URL = "https://prive.luxurylinkup.com";

export const http = axios.create({
  baseURL: BASE_URL_v1,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

export const protectedHttp = axios.create({
  baseURL: BASE_URL_v1,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

export const protectedMediaHttp = axios.create({
  baseURL: BASE_URL_v1,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
});

protectedHttp.interceptors.request.use(async (config) => {
  const token = JSON.parse(localStorage.getItem("uu_session"));

  if (token !== null) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

protectedMediaHttp.interceptors.request.use(async (config) => {
  const token = JSON.parse(localStorage.getItem("uu_session"));

  if (token !== null) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

protectedHttp.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("uu_session");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

protectedMediaHttp.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("uu_session");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);
