import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CreateAdd, Dashboard } from "..";
import { getClientInstance } from "../../helpers/middleware.helper";
import UpdateAdvertisement from "./UpdateAdvertisement";
import AdminTopbar from "../../components/layouts/AdminTopbar";
import AllAds from "./AllAds";
import appConfigStore from "../../store/appConfigStore";
import AllAgencies from "./AllAgencies";
import AllIndependentEscorts from "./AllIndependentEscorts";
import AddAgency from "./AddAgency";
import AddIndependentEscort from "./AddIndependentEscort";
import { Helmet } from "react-helmet";
import { LogoImage } from "../../assets/images";
import AllPartners from "./AllPartners";
import Links from "./Links";
import EditLink from "./EditLink";

const Admin = () => {
  const { isLoading, setIsLoading } = appConfigStore();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const initializeInstance = async () => {
      setIsLoading(true);
      const instance = await getClientInstance();

      if (instance === 1) {
        if (location.pathname === "/admin" || location.pathname === "/admin/")
          navigate("/admin/dashboard");
      } else if (instance === 2) {
        navigate("/agency/dashboard");
      } else if (instance === 3) {
        navigate("/escort/dashboard");
      } else {
        navigate("/");
      }
      setIsLoading(false);
    };

    initializeInstance();
  }, [location.pathname, navigate, setIsLoading]);

  const LinksRouter = () => {
    return (
      <Routes>
        <Route path="/" element={<Links />} />
        <Route path="/edit/:city" element={<EditLink />} />
      </Routes>
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Privé - Dashboard`} </title>
        <meta name="title" content={`Privé - Dashboard`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Privé - Dashboard`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://privé.xyz`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Privé - Dashboard`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://privé.xyz`} />

        <link rel="canonical" href={`https://privé.xyz`} />
      </Helmet>
      <AdminTopbar />
      {isLoading && <div className="overlay" />}

      <Routes>
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="all-ads" element={<AllAds />} />
        <Route path="all-agencies" element={<AllAgencies />} />
        <Route
          path="all-idependent-escorts"
          element={<AllIndependentEscorts />}
        />
        <Route path="add-agency" element={<AddAgency />} />
        <Route path="add-independent" element={<AddIndependentEscort />} />
        <Route path="create" element={<CreateAdd />} />
        <Route path="/update/:id" element={<UpdateAdvertisement />} />
        <Route path="links/*" element={<LinksRouter />} />
        <Route path="partners" element={<AllPartners />} />
      </Routes>
    </>
  );
};

export default Admin;
